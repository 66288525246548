import * as React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

// ========== TYPES ========== //

interface ComponentProps {
  text: string;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) => createStyles({}));

// ========== COMPONENT ========== //

export default function Displayer({ text }: ComponentProps) {
  const classes = useStyles();

  return (
    <Box>
      <Typography>{text}</Typography>
    </Box>
  );
}
