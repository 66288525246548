import React, { useState } from 'react';
import Layout from '../../components/layout/CommonLayout/Layout';
import SEO from '../../components/atoms/SEO';
import GenericPage from '../../components/layout/CommonLayout/GenericPage';
import Uploader from '../../components/pages/let/bulk-upload-properties/Uploader';
import Displayer from '../../components/pages/let/bulk-upload-properties/Displayer';
import { NetworkStatus } from '../../api/utils/types';

export default function BulkNew() {
  const [uploadResult, setUploadResult] = useState('');
  const [uploadError, setUploadError] = useState<Error | null>(null);
  const [uploadStatus, setUploadStatus] = useState<NetworkStatus>('idle');

  const canUpload = uploadStatus !== 'loading';

  return (
    <Layout>
      <SEO title="Bulk upload properties" />
      <GenericPage>
        <Uploader
          canUpload={canUpload}
          setUploadResult={setUploadResult}
          setUploadError={setUploadError}
          setUploadStatus={setUploadStatus}
        />
        <Displayer text={uploadError ? uploadError.message : uploadResult} />
      </GenericPage>
    </Layout>
  );
}
