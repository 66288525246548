import React, { useState } from 'react';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import useAuthToken from '../../../../store/auth/hooks/useAuthToken';
import useAuthProfile from '../../../../store/auth/hooks/useAuthProfile';
import { NetworkStatus } from '../../../../api/utils/types';

// ========== TYPES ========== //

interface ComponentProps {
  canUpload: boolean;
  setUploadResult: (result: string) => void;
  setUploadError: (error: Error | null) => void;
  setUploadStatus: (status: NetworkStatus) => void;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) => createStyles({}));

// ========== COMPONENT ========== //

export default function Uploader({
  canUpload,
  setUploadResult,
  setUploadError,
  setUploadStatus,
}: ComponentProps) {
  const classes = useStyles();

  const authToken = useAuthToken();
  const authProfile = useAuthProfile();

  const [curFile, setCurFile] = useState<File | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    if (fileList) {
      setCurFile(fileList[0]);
      console.log(`Successfully updated current file`);
    } else {
      setCurFile(null);
      console.log(`Successfully cleared current file`);
    }
  };

  const handleUploadBtnClick = async (e: React.MouseEvent) => {
    if (authToken && authProfile && curFile && canUpload) {
      const formData = new FormData();
      formData.append('sourcedata', curFile);

      const url = `${process.env.GATSBY_API_HOST}/api/propertyPvt/bulkCreate`;

      const init = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${authToken}`,
          'BTR-Organization': `${authProfile.user.organization_ids[0]}`,
        },
        body: formData,
      };

      setUploadStatus('loading');
      setUploadError(null);

      const res = await fetch(url, init);

      if (!res.ok) {
        const errMsg = await res.text();

        setUploadResult('');
        setUploadError(
          new Error(
            `Could not process the provided data. Reason: ${res.status} - ${res.statusText} - ${errMsg}`
          )
        );
        setUploadStatus('error');
      } else {
        const json = await res.json();

        console.log(json);

        setUploadResult(
          `Processed provided data. Check your console for result...`
        );
        setUploadError(null);
        setUploadStatus('success');
      }
    }
  };

  const handleClearBtnClick = () => {
    setCurFile(null);
    console.log(`Successfully cleared current file`);
  };

  return (
    <Box>
      <label htmlFor="bulk-upload-properties-input">
        Select your Excel file:
      </label>
      <input
        type="file"
        id="bulk-upload-properties-input"
        name="sourcedata"
        accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={handleChange}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUploadBtnClick}
        disabled={!canUpload}
      >
        Upload
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClearBtnClick}
        disabled={!curFile}
      >
        Clear
      </Button>
    </Box>
  );
}
